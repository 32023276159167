<template>
  <a-modal
    v-model:visible="visible"
    :maskClosable="false"
    :keyboard="false"
    width="1000px"
    :title="$t('RevisionOfTrainingLeaders')"
  >
    <template #footer>
      <a-button @click="visible = false">{{ $t("Pub_Close") }}</a-button>
    </template>
    <a-table
      size="small"
      :scroll="{ x: 884 }"
      :columns="columns"
      rowKey="subPlanId"
      :data-source="dataList"
      :pagination="false"
    >
      <template #index="{ index }">
        {{ index + 1 }}
      </template>
      <template #tooltip="{ text }">
        <a-tooltip :title="text">{{ text }}</a-tooltip>
      </template>
      <template #date="{ text }">
        <template v-if="text && text.length">
          <a-tooltip :title="text[0] + '~' + text[1]"
            >{{ text[0] }} ~ {{ text[1] }}</a-tooltip
          >
        </template>
      </template>
      <template #member="{ text }">
        <a-tooltip placement="topLeft">
          <template #title>
            <template v-for="(item, index) in text" :key="index">
              <OpenData
                class="pr6"
                type="userName"
                :openid="item.lecturerName || item.realName"
                v-if="item.userId"
              />
              <span class="pr6" v-else>{{
                item.lecturerName || item.realName
              }}</span>
            </template>
          </template>
          <div class="w160 ellipsis">
            <template v-for="(item, index) in text" :key="index">
              <OpenData
                class="pr6"
                type="userName"
                :openid="item.lecturerName || item.realName"
                v-if="item.userId"
              />
              <span class="pr6" v-else>{{
                item.lecturerName || item.realName
              }}</span>
            </template>
          </div>
        </a-tooltip>
      </template>
      <template #action="{ record }">
        <!-- 选择 -->
        <a-button type="link" @click="addMember(record.subPlanId)">{{
          $t("SelectThePersonInCharge")
        }}</a-button>
      </template>
    </a-table>
    <selectMember ref="selectMemberRef" @on-select="onSelect" />
  </a-modal>
</template>
<script setup>
import { useI18n } from "vue-i18n";
import { ref, reactive, getCurrentInstance } from "vue";
import { getPlanDetail, submitPlanManager } from "@/api/auditCenter";
import OpenData from "@/components/OpenData.vue";
import selectMember from "@/components/selectMember.vue";

const { t: $t } = useI18n();
const { proxy } = getCurrentInstance();
const visible = ref(false);
const columns = ref([
  {
    // title: "序号",
    title: $t("map.table.serial"),
    dataIndex: "index",
    width: 60,
    ellipsis: true,
    slots: { customRender: "index" },
  },
  {
    // title: "培训名称",
    title: $t("NameOfTraining"),
    dataIndex: "trainingName",
    width: 150,
    ellipsis: true,
    slots: { customRender: "tooltip" },
  },
  {
    // title: "培训时间",
    title: $t("Lab_Evaluate_T_TrainingDate"),
    dataIndex: "trainingTime",
    width: 200,
    ellipsis: true,
    slots: { customRender: "date" },
  },
  {
    // title: "培训讲师",
    title: $t("TrainingLecturers"),
    dataIndex: "teacherList",
    width: 180,
    ellipsis: true,
    slots: { customRender: "member" },
  },
  {
    // title: "培训负责人",
    title: $t("TrainingLeaders"),
    dataIndex: "responsibleUserList",
    width: 180,
    ellipsis: true,
    slots: { customRender: "member" },
  },
  {
    // title: "操作",
    title: $t("cm_operate"),
    dataIndex: "action",
    width: 100,
    align: "center",
    fixed: "right",
    slots: { customRender: "action" },
  },
]);
const dataList = ref([]);
const tempId = ref(0);

function show(id) {
  tempId.value = id;
  getDetail();
  visible.value = true;
}
async function getDetail() {
  const res = await getPlanDetail(tempId.value);
  if (res.ret === 0) {
    dataList.value = res.data.subPlanList || [];
  }
}

const selectMemberRef = ref(null);
const subPlanId = ref(0);

function addMember(id) {
  subPlanId.value = id;
  selectMemberRef.value.show();
}
async function onSelect(list) {
  const param = {
    planId: tempId.value,
    subPlanList: [
      {
        responsibleUids: list.join(","),
        subplanId: subPlanId.value,
      },
    ],
  };
  const res = await submitPlanManager(param);
  if (res.ret === 0) {
    proxy.$message.success($t("CM_Success"));
    getDetail();
  } else {
    proxy.$message.error(res.msg);
  }
}

const emit = defineEmits(["emit"]);
defineExpose({
  show,
});
</script>
<style lang="less" scoped>
.pr6 {
  padding-right: 6px;
}
.w160 {
  width: 160px;
}
</style>
